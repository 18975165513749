import {httpGet, httpPost} from '@/plugins/request'


/**
 * 查询订阅
 * @returns {*}
 */
export function apiGetSubscribe(query)
{
    return httpGet('/user/subscribe', query)
}


/**
 * 启用/停用订阅
 * @returns {*}
 */
export function apiStoreSubscribe(query)
{
    return httpPost('/user/subscribe', query)
}
