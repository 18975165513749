import {httpGet, httpPost} from '@/plugins/request'

/**
 * 拒绝原因
 * @param id
 * @param password
 * @returns {*}
 */
export function apiGetMerchantTaskSale(id, password)
{
    return httpGet(`/merchant/task/sale/${id}`, {
        'password': password
    })
}

/**
 * 确认提交
 * @param id
 * @param data
 * @returns {*}
 */
export function apiConfirmMerchantTaskSale(id, data)
{
    return httpPost(`/merchant/task/sale/${id}/confirm`, data)
}

/**
 * 发送群消息
 * @param data
 * @returns {*}
 */
export function apiSendWechatMessage(data)
{
    return httpPost(`/merchant/wechat/send`, data)
}
